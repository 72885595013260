<template>
  <b-overlay :show="loading" class="row">
   <div class="col-12 col-lg-6 c g">
    <div class="card card-body">
      <div class="form-check">
        <label class="form-check-label">
          <input type="checkbox" class="form-check-input" v-model="process_before_send_bool" :value="true">
          تفعيل معالجة الارقام عند الارسال
        </label>
      </div>
      <br>
      <div class="form-group g" v-if="process_before_send_bool">
        <label for="" class="g">اختر الدولة</label>
        <select class="form-control" v-model="user.process_before_send">
         <option value="--">-- اختر --</option>
          <option value="sa">السعودية</option>
          <option value="eg">مصر</option>
        </select>
      </div>
      <div class="col-12 text-center g">
        <button class="btn btn-success" @click="save()">
          <i class="fa fa-save"></i>
          حفظ التعديلات
        </button>
      </div>
      <hr>
    * تفيد هذه الخدمة عند ارسال رسالة او اكثر لأي رقم سيتم معالجة الرقم للصيغة الدولية قبل الارسال مثل:<br>
    055444444
    <br>ستتحول الى
    <br>
    96655444444
    </div>
   </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BOverlay, BModal, VBModal } from "bootstrap-vue";
var QRCode = require("qrcode");
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BModal,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      loading: true,
      user: JSON.parse(localStorage.getItem("user")),
      process_before_send_bool: false
    };
  },
  created() {
    this.loading = false;
    if(this.user.process_before_send && this.user.process_before_send != 'no'){
        this.process_before_send_bool = true
    }
  },
  methods: {
    save() {
      var g = this;
      $.post(api + "/user/auth/process_before_send", {
          jwt: this.user.jwt,
          process_before_send_bool: this.process_before_send_bool,
          process_before_send: this.user.process_before_send
      })
      .then(function(r) {
          if (r.status == 100) {
              g.$toast({
                  component: ToastificationContent,
                  props: {
                      title: "تم الحفظ",
                      icon: "CheckIcon",
                      variant: "success",
                  },
              });
              location.reload()
          } else {
              g.$toast({
                  component: ToastificationContent,
                  props: {
                      title: r.response,
                      icon: "EditIcon",
                      variant: "danger",
                  },
              });
          }
      })
      .catch(function() {
          g.loading = false;
          g.$toast({
              component: ToastificationContent,
              props: {
                  title: "حدثت مشكلة في الاتصال",
                  icon: "EditIcon",
                  variant: "danger",
              },
          });
      });
    }
  },
};
</script>
